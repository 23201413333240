import { Button, Image, Link } from "@global";
import { Maybe, SanityCollection, SanityCollectionRange } from "@graphql-types";
import { MOBILE_BREAKPOINT } from "@util/constants";
import { Container, StyledOverlay } from "@util/standard";
import React, { useState } from "react";
import styled from "styled-components";

interface Props {
  data?: Maybe<SanityCollectionRange> | undefined;
}

const StyledLink = styled(Link)`
  p {
    line-height: 30px;
    font-weight: 600;
    @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
      font-size: 20px;
    }
  }

  a::hover {
    text-decoration: none;
  }
`;

const OurRange = (props: Props) => {
  const { data } = props;

  if (data == null || data.collections.length === 0) {
    return null;
  }

  const [backgroundImage, setBackgroundImage] = useState(
    data.backgroundImage?.asset?.gatsbyImageData
  );

  const handleBackgroundHover = (e: Maybe<SanityCollection>) => {
    if (e == null) return;
    setBackgroundImage(e.main?.hero?.videoUrl?.image?.asset?.gatsbyImageData);
  };

  return (
    <Container>
      <Image width="100vw" isBackground imageData={backgroundImage}>
        <Container
          width="100vw"
          height="auto"
          margin="0px"
          display="flex"
          flexDirection="row"
        >
          <StyledOverlay height="100%" />
          <Container
            width="35%"
            margin="50px"
            padding="50px"
            display="flex"
            flexDirection="column"
            zIndex={20}
            mobileWidth="100%"
            mobileMargin="40px 20px"
            backgroundColor="yellow"
          >
            <h3>{data.title}</h3>
            <Container
              flexDirection="column"
              margin="26px 0px"
              mobileMargin="0 0 20px 0"
            >
              {data.collections &&
                data.collections.map(collection => {
                  return (
                    <StyledLink
                      key={collection?.main?.slug?.current}
                      linktext={collection?.main?.title}
                      url={collection?.main?.slug?.current}
                      internallink
                      fontSize={20}
                      color="black"
                      opacity={0.5}
                      hoverOpacity={1}
                      onMouseEnter={() => handleBackgroundHover(collection)}
                    />
                  );
                })}
            </Container>

            <Button theme="transparent" text="View all" linkTo="/shop"></Button>
          </Container>
        </Container>
      </Image>
    </Container>
  );
};

export default OurRange;
